import React from "react"
import PropTypes from "prop-types"
import "../styles/layout.css"

if (typeof window !== "undefined") {
    // eslint-disable-next-line global-require
    require("smooth-scroll")('a[href*="#"]')
  }
  
const Layout = ({ children }) => {

  return (
    <>
        <div style={{ margin: `0`, color: `#5e5c5c`, fontFamily: `Roboto`}}>
        <main>{children}</main>
        <footer>
            <span style={{ paddingLeft: `25px`, float: `left`, color: `#5e5c5c`}} >
                © Liquid Rocketry Lab {new Date().getFullYear()}
            </span >
            <span style={{ paddingRight: `25px`, float: `right`, color: `#5e5c5c`}} >Designed and Built by  
            <a style={{ color: `#5e5c5c`}} href="https://www.linkedin.com/in/sagnik10/"> Sagnik B</a></span>
        </footer>
        </div>
    </>
    )
}

Layout.propTypes = {
  children: PropTypes.node.isRequired,
}

export default Layout
